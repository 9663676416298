import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const ThankYouProps = {
  thankyouSectionProps: {
    heading: (
      <>
        <span className="highlight-head">Thanks</span> for reaching out to us!
      </>
    ),
    para: (
      <>
        We appreciate you taking the time to connect with us. <br />
        <br />
        Your submission is important to us and our team is reviewing the details
        to ensure that we connect you with the right expert. <br />
        <br />
        We usually get back within a business day and will be in touch soon.
      </>
    ),
  },
  SimformBetterData: {
    heading: (
      <>
        Meanwhile, read this and get to know{' '}
        <span className="highlight-head">Simform better</span>
      </>
    ),
    subtext: (
      <>
        With our deep engineering DNA and unique co-engineering model, we build
        future-proof digital products for high growth ISVs and tech enabled
        enterprises.
        <br />
        <br />
        We are proud of our…
      </>
    ),
    advertiseCards: [
      {
        heading: 'Success Stories & Client Results',
        url: '/case-studies/',
        type: EXTERNAL_LINK,
        subtext:
          'Real-world examples of how Simform drives business growth and solves challenges.',
      },
      {
        heading: 'Mission & Vision',
        url: '/about-us/',
        subtext:
          'Empowering businesses with scalable software for digital success and innovation.',
      },
      {
        heading: 'Tech Trends & Best Practices',
        url: '/blog/',
        type: EXTERNAL_LINK,
        subtext:
          'Insights on software development trends and tips for agile transformation.',
      },
    ],
  },
}
