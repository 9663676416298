import React from 'react'
import NavLink from 'components/NavLink/NavLink'
import HubSpotFooterForm from 'components/HubSpotFooterForm/HubSpotFooterForm'
import { NewsletterSectionStyle } from './NewsletterSection.styles'

export default function NewsletterSection() {
  return (
    <NewsletterSectionStyle>
      <div className="container">
        <div className="textwidget">
          <h2>Sign up for the free Newsletter</h2>
          <p>For exclusive strategies not found on the blog</p>
          <span class="note">Sign up today!</span>
          <div class="subscribe-form-container">
            <HubSpotFooterForm
              region="na1"
              portalId="22295906"
              formId="cbbc92fe-f16e-4dca-9a51-7f5ee56d7d8d"
            />
          </div>
        </div>
      </div>
    </NewsletterSectionStyle>
  )
}
