import React from 'react'
import { AdvertiseBoxWrap, SimformBetterStyle } from './SimformBetter.style'
import NavLink from 'components/NavLink/NavLink'

export default function SimformBetter(props) {
  const { SimformBetter } = props

  return (
    <SimformBetterStyle>
      <div className="container">
        <div className="heading-wrap">
          <h2>{SimformBetter.heading}</h2>
        </div>

        <p className="sub-text">{SimformBetter.subtext}</p>

        <AdvertiseBoxWrap>
          {SimformBetter.advertiseCards.map((advertiseCards, index) => (
            <NavLink
              key={index}
              navLink={advertiseCards.url}
              linkType={advertiseCards.type}
              className="advertise-box-wrap"
            >
              <h6 className="h6 heading">{advertiseCards.heading}</h6>

              <p className="info">{advertiseCards.subtext}</p>
            </NavLink>
          ))}
        </AdvertiseBoxWrap>
      </div>
    </SimformBetterStyle>
  )
}
