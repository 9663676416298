import styled from 'styled-components'
import {
  md,
  sm,
  themeColor,
  lg,
  white,
  sh,
  primaryColor,
  metaFont,
} from 'config/variables'

export const ThankyouSectionStyle = styled.section`
position: relative;
 padding-top: 150px;
margin-bottom: 110px;
${lg(`
    padding-top: 110px;
    margin-bottom: 90px;
`)}
${md(`
    padding-top: 110px;
    margin-bottom: 90px;
`)}
${sm(`
    padding-top: 80px;
    margin-bottom: 50px;
`)}
  .heading-wrapper {
    max-width: 100%;
    text-align:left;
  }
  .heading-para {
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    ${sh(`
      max-width: 615px;
    `)}
    ${md(`
      display: none;
    `)}
    p {
      font-size: 24px;
      letter-spacing: -0.15px;
      line-height: 33px;
      margin-bottom: 34px;
      ${sh(`
        font-size: 21px;
        line-height: 30px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 24px;
      `)}
      ${sm(`
        font-size: 18px;
        line-height: 28px;
        `)}
    }
  }`
