import React, { Component } from 'react'
import { ThankyouSectionStyle } from './ThankyouSection.Styles'

function ThankyouSection(props) {
  const { thankyouSectionData } = props
  console.log(thankyouSectionData)
  return (
    <ThankyouSectionStyle>
      <div className="container">
        {thankyouSectionData.heading && (
          <div className="heading-wrapper">
            <h1 className="h3">{thankyouSectionData.heading}</h1>
          </div>
        )}
        {thankyouSectionData.para && (
          <div className="heading-para">
            <p>{thankyouSectionData.para}</p>
          </div>
        )}
      </div>
    </ThankyouSectionStyle>
  )
}

export default ThankyouSection
