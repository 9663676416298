import styled from 'styled-components'
import { lg, sm, md, themeColor, primaryColor } from 'config/variables'

export const SimformBetterStyle = styled.section`
  .heading-wrap {
    font-size: 44px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.478px;
  }
  .sub-text {
    color: #3d3d3d;
    font-family: Graphik;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 46px;
  }
`
export const AdvertiseBoxWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  ${lg(`
         gap: 16px; 
        `)}
  ${md(`
      flex-wrap: wrap;
           grid-template-columns: repeat(1, 1fr);
        `)}
  ${sm(`
      flex-wrap: wrap;
           grid-template-columns: repeat(1, 1fr);
        `)}
  .advertise-box-wrap {
    padding: 26px 21px;
    background-color: #f5f7ff;
    border:1px solid #f5f7ff;
    border-radius: 4px;
    position: relative;
    transition: all 0.4s ease-in-out;
    width: 100%;
    color: ${primaryColor};
    &:hover {
      border-color:${themeColor};
    }
    .heading {
      color: ${themeColor};
      margin-bottom: 12px
    } 
    p {
      margin:0px;
    }
  }
`
