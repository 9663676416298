import HubSpotFooterForm from 'components/HubSpotFooterForm/HubSpotFooterForm'
import { images } from 'config/images'
import { lg, md, xl } from 'config/variables'

const { default: styled } = require('styled-components')

export const NewsletterSectionStyle = styled.section`
  width: 100%;
  background: #ffe0f9;
  text-align: center;
  padding: 66px 0 64px;
  position: relative;
  z-index: 9;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    background: linear-gradient(100deg, #ecc2e5, #edc4e6);
    top: 0;
    left: 0;
  }
  .textwidget {
    position: relative;
    .note {
      position: absolute;
      font-size: 18px;
      font-family: "Graphik";
      font-weight: 500;
      line-height: 24px;
      color: #ef5366;
      right: 70px;
      top: 48px;
      ${xl(`
        right: -20px;
      `)}
      ${lg(`
        right: -20px;
        top: 50px;
      `)}
      ${md(`
        display: none;
      `)}
      &:after {
        content: "";
        position: absolute;
        width: 50px;
        height: 55px;
        background: url(${images.signupArrow}) no-repeat; 
        bottom: -58px;
        left: -40px;
        ${lg(`
            left: 10px;
      `)}
      }
    }
  }
  h2 {
    font-size: 34px;
    line-height: 48px;
    letter-spacing: -0.37px;
    color: #000;
    margin-bottom: 2px;
    font-family: 'CircularStd';
  }
  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 25px;
    color: #000;
    margin: 0 0 50px;
    ${lg(`
        margin: 0 0 24px;
    `)}
  }
  .subscribe-form-container {
    max-width: 615px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    background: transparent;
    form {
      display: flex;
      align-items: center;
      background: transparent;
      width: 100%;
      flex-wrap: nowrap;
      label {
        display: none;
      }
      .hs-form-field {
        margin: 0;
        max-width: calc(100% - 188px);
        margin-right: -4px;
        width: 100%;
        position: relative;
        .input {
          .hs-input {
            background: #fff;
            padding: 11px 23px 12px;
            height: 100%;
            font-size: 18px;
            line-height: 27px;
            color: rgba(61, 61, 61, 0.5);
            width: 100%;
            border: 0;
            border-radius: 4px;
            font-family: 'Graphik';
            font-weight: 400;
            outline: none;
          }
        }
      }
      .hs-submit  {
        margin: auto;
        width: auto;
        max-width: 100%;
        padding: 0;
    }
  }
`
